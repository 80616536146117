import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isShowDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShowDialog) = $event)),
    title: "เลือก การถ่ายทอดสดที่ต้องการ",
    "close-on-press-escape": false,
    "close-on-click-modal": false,
    center: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: _ctx.onCancelDialog }, {
          default: _withCtx(() => [
            _createTextVNode("ยกเลิก")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          disabled: !_ctx.selectedLive,
          onClick: _ctx.onConfirmConnect,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("ยืนยัน")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        ref: "multipleTable",
        data: _ctx.liveList,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, { width: "200" }),
          _createVNode(_component_el_table_column, {
            label: "ชื่อการถ่ายทอดสด",
            prop: "title"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.selectedLive,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLive) = $event)),
                label: scope.row,
                size: "large"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.title), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}