
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import LiveProductTable from '@/components/live-stream/LiveProductsTable.vue'
import LiveSelectDialog from '@/components/live-stream/LiveSelectDialog.vue'
import { getBankList } from '@/api/shop.api'
import { CreateLivePayload, EditLivePayload, LivePaymentType, SHIPMENT_STATUS } from '@/api/live/live.type'
import { useRoute, useRouter } from 'vue-router'
import { checkLiveStatus, createLive, editLive, getLiveDetail, startLive } from '@/api/live/live.api'
import { ElNotification } from 'element-plus'
import { LIVE_STATUS } from '@/api/live/live.type'
import BankAddDialog from '@/components/bank/BankAddDialog.vue'
import { BankDetail } from '@/api/bank.api'
import { useLiveStore } from '@/pinia/live/live.pinia'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useFacebookPageStore } from '@/pinia/facebook-page/facebook-page.pinia'
import { useCourierStore } from '@/pinia/courier/courier.pinia'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { BANK_ACCOUNT_TYPE } from '@/config/bank'

export enum ShippingMethod {
    COD = 'COD',
    TRANSFER = 'TRANSFER'
}

export default defineComponent({
	name: 'LiveStreamSetup',
	components: {
		LiveProductTable,
		LiveSelectDialog,
		BankAddDialog,
	},
	setup() {
		const route = useRoute()
		const router = useRouter()
		const facebookPageStore = useFacebookPageStore()
		const liveStore = useLiveStore()
		const shopStore = useShopStore()
		const courierStore = useCourierStore()
		const { logEvent } = useAnalytics()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		const liveProductTableRef = ref()
		const selectLiveDialogRef = ref()
		const liveList = ref([])
		const setupFormRef = ref()
		const liveData = ref<any>(null)
		const formData = reactive({
			isSubmitting: false,
			liveId: null,
			pageId: null as string|null,
			name: '',
			courier: '',
			paymentType: ['TRANSFER'],
			bankList: [] as any,
			bank: [],
			shipmentStatus: SHIPMENT_STATUS.FREE,
			shipmentFlatRate: 0,
		})
		const bankState = reactive({
			isShowBankDialog: false,
			accountName: '',
			accountNumber: '',
			accountIssuerId: '',
			bankId: null,
		})

		const onSubmitBank = () => {
			//
			fetchBankList()
		}
		const disabledLiveComplete = computed(() => {
			return liveData.value?.status !== LIVE_STATUS.LIVE && liveData.value?.status !== LIVE_STATUS.COMPLETED
		})
		const currentPage = computed(() => facebookPageStore.findPageByPageId(route.params.pageId as string))

		const validateRules = computed(() => ({
			name: [{ type: 'string', required: true, message: 'กรุณาระบุชื่อการถ่ายทอดสด', trigger: ['change', 'blur'] }],
			paymentType: [{ type: 'array', required: true, message: 'กรุณาเลือกช่องทางการชำระเงินอย่างน้อย 1 ช่องทาง', trigger: ['change', 'blur'] }],
			...formData.shipmentStatus === SHIPMENT_STATUS.FLAT_RATE && { shipmentFlatRate: [{ type: 'number', required: true, message: 'กรุณาระบุจำนวนค่าขนส่งแบบเหมา', trigger: ['change', 'blur'], validator: (_: any, value: any) => value > 0 }] },
			...formData.paymentType.includes('TRANSFER') && { bank: [{ type: 'array', required: true, message: 'กรุณาเลือกธนาคารในการโอนเงินอย่างน้อย 1 ธนาคาร', trigger: ['change', 'blur']  }] }
		}))

		const onSaveSetup = async (isInSaveDraftStep = true) => {
			const [isSetupFormValid, isProductFormValid] = await Promise.all([setupFormRef.value.validate(), liveProductTableRef.value.validateAll()])
			if (!isSetupFormValid || !isProductFormValid) throw new Error('validation fail')
			// validate product length must more than 1 items
			if (liveStore.liveProducts?.length < 1) {
				return Promise.reject(ElNotification({
					title: 'ไม่สำเร็จ',
					message: 'กรุณาเลือกสินค้าในการถ่ายทอดสดอย่างน้อย 1 สินค้า',
					type: 'error'
				}))
			}
			try {
				if (route.query.liveId) {
					// edit
					const liveId = route.query.liveId as string
					const editPayload: EditLivePayload = {
						name: formData.name,
						fanpageId: currentPage.value?._id as string,
						courierId: formData.courier ,
						shopId: shopStore.selectedShopId as string,
						shipment: {
							status: formData.shipmentStatus,
							flatRatePrice: formData.shipmentFlatRate
						},
						payment: formData.paymentType as LivePaymentType[],
						bank: formData.bank,
						products: liveStore.liveProducts.map((product: any) => ({
							product: product._id,
							price: product.price,
							cfCode: product.cfCode,
							enable: product.enable,
							quantity: product.quantity,
						}))
					} 
					
					await editLive(liveId, editPayload)
					
				} else {
					// create
					const createPayload: CreateLivePayload = {
						name: formData.name,
						fanpageId: currentPage.value?._id as string,
						courierId: formData.courier ,
						shopId: shopStore.selectedShopId as string,
						payment: formData.paymentType,
						bank: formData.bank,
						shipment: {
							status: formData.shipmentStatus,
							flatRatePrice: formData.shipmentFlatRate
						},
						products: liveStore.liveProducts.map((product) => ({
							product: product._id,
							price: product.price,
							cfCode: product.cfCode,
							enable: product.enable,
							quantity: product.quantity,
						}))
					}

					const { data: afterCreate } = await createLive(createPayload)
					// Hack Push in the same page to use route.query.liveId after create
					await router.push({ path: `/live-stream/${route.params.pageId}/setup`, query: { liveId: afterCreate._id }})
				}
			} catch (error) {
				console.log('error', error)
				return Promise.reject(error)
			} finally {
				// clear to empty array
				if (isInSaveDraftStep) {
					liveStore.updateLiveProducts([])
				}
			}
			if (isInSaveDraftStep) {
				ElNotification({
					title: 'สำเร็จ',
					message: 'บันทึกการถ่ายทอดสดสำเร็จ',
					type: 'success',
					showClose: !isReactNativeWebview.value
				})
			}

		}

		const onDraftLive = async () => {
			formData.isSubmitting = true
			try {
				await onSaveSetup()
				await router.push({ path: `/live-stream/${route.params.pageId}` })
			} catch (error) {
				console.log('[ERROR DRAFT LIVE:] ', error)	
			} finally {
				formData.isSubmitting = false

				// logs event
				logEvent({ eventName: EVENT_NAME.createLiveCF })
			}
		}
		const onStartLive = async () => {	
			formData.isSubmitting = true
			try {
				// save live
				await onSaveSetup(false)

				const { data: { liveSteamingPosts } } = await checkLiveStatus(currentPage.value?._id as string, route.query.liveId as string)
				if (liveSteamingPosts.length < 1) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไม่สามารถเริ่มการถ่ายทอดสดได้ เนื่องจาก เพจของคุณยังไม่ได้มีการถ่ายทอดสด กรุณาไปที่เพจแล้วทำการถ่ายทอดสดอย่างน้อย 1 รายการ',
						type: 'error',
						showClose: !isReactNativeWebview.value
					})
				} else {
					if (liveSteamingPosts.length > 1) {
						// Waiting confirm Dialog
						liveList.value = liveSteamingPosts
						const { liveStreamId, videoId, title } = await selectLiveDialogRef.value.showDialog()
						// Start Live
						const liveId = route.query.liveId as string
						await startLive(liveStreamId, liveId, videoId, title)
					}
					// === 1
					if (liveSteamingPosts.length === 1) {
						// Start Live
						const liveId = route.query.liveId as string
						const liveVideo = liveSteamingPosts?.[0]
						await startLive(liveVideo.id, liveId, liveVideo.video?.id, liveVideo.title)
					}
					// Push to another path
					liveStore.updateLiveProducts([]) // clear product list
					await router.push({ path: `/live-stream/${route.params.pageId}/live/${route.query.liveId}` })
				}
			} catch (error: any) {
				console.log('[ERROR]: ', error)
				if (error.response?.data?.resDesc === 'LIVE_MUST_CONTAIN_AT_LEAST_ONE_PRODUCT') {
					ElNotification({
						title: 'ไม่สำเร็จ',
						message: 'กรุณาเลือกสินค้าในการถ่ายทอดสดอย่างน้อย 1 สินค้า',
						type: 'error',
						showClose: !isReactNativeWebview.value
					})
				}
			} finally {
				formData.isSubmitting = false

				// logs event
				logEvent({ eventName: EVENT_NAME.startLiveCF })
			}
		}

		const fetchBankList = () => {
			const shopId = shopStore.selectedShopId as string
			getBankList(shopId)
				.then((response) => {
					formData.bankList = response.data.bank
					if (formData.bank.length <= 0) {
						const bankDefault: any = formData.bankList.map((e: BankDetail) => {
							return e._id
						})
						formData.bank = bankDefault
					}
				})
		}

		onMounted(() => {
			if (route.query.pageId) {
				formData.pageId = route.query.pageId as string
			}
			fetchBankList()
			onGetCourier()

			// Get Detail if edit live
			if (route.query.liveId) {
				getLiveDetail(route.query.liveId as string)
					.then(({ data: liveDetail }) => {
						liveData.value = liveDetail
						// Live already start redirect to live page
						if (liveDetail.status === 'LIVE') {
							return router.push({ path: `/live-stream/${route.params.pageId}/live/${route.query.liveId}` })
						}
						// Update Product table
						formData.paymentType = liveDetail.payment
						formData.bank = liveDetail.bank
						formData.courier = liveDetail.courierId
						formData.shipmentFlatRate = liveDetail.shipment?.flatRatePrice
						formData.shipmentStatus = liveDetail.shipment?.status

						const liveProduct = liveDetail.products.map((product: any) => ({
							...product,
							images: product.product?.images,
							sku: product.product?.sku,
							_id: product.product?._id, // productId change key
							productObjectId: product._id, // _id changeKey
						}))

						liveStore.updateLiveProducts(liveProduct)
						formData.name = liveDetail.name
					})
			} else {
				// Clear Empty liveProduct
				liveStore.updateLiveProducts([])
			}
		})

		const onGetCourier = async() => {
			try {
				await courierStore.getCourierList()
				const res = courierStore.someCourierList
				if(res){
					const courier: {name: string, _id: string, image: string} | any = courierStore.someCourierList.find((e: {name: string, _id: string, image: string}) => {
						return e.name === 'Flash'
					})
					formData.courier = courier?._id
				}
			} catch (_) {
				//
			}
		}

		return {
			liveProductTableRef,
			formData,
			onSaveSetup,
			onStartLive,
			onGetCourier,
			validateRules,
			setupFormRef,
			onDraftLive,
			selectLiveDialogRef,
			liveList,
			liveData,
			LIVE_STATUS,
			disabledLiveComplete,
			onSubmitBank,
			bankState,
			fetchBankList,
			courierStore,
			BANK_ACCOUNT_TYPE
		}
	}
})
