
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
	name: 'LiveSelectDialog',
	props: {
		liveList: {
			type: Array,
			default: () => []
		}
	},
	setup(_, { emit }) {
		const isShowDialog = ref(false)
		const selectedLive = ref<any>(null)
		const promiseObject = reactive({
			resolve: Promise.resolve,
			reject: Promise.reject
		})
		const showDialog = (): Promise<void> => {
			return new Promise((resolve, reject) => {
				isShowDialog.value = true
				promiseObject.resolve = resolve as any
				promiseObject.reject = reject as any
			})
		}
		const onConfirmConnect = () => {
			// resolve
			promiseObject.resolve({ liveStreamId: selectedLive.value?.id, videoId: selectedLive.value?.video?.id, title: selectedLive.value?.title })
			clearPromiseDialog()
		}

		const onCancelDialog = () => {
			promiseObject.reject()
			clearPromiseDialog()
		}

		const clearPromiseDialog = () => {
			isShowDialog.value = false
			// clear
			promiseObject.resolve = Promise.resolve
			promiseObject.reject = Promise.reject
		}

		return {
			isShowDialog,
			selectedLive,
			onConfirmConnect,
			showDialog,
			onCancelDialog,
		}
	},
})
